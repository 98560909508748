import {
  ICandidateApplication,
  Candidate,
  IPinnedApplication,
  IApplication,
  TestQuestion,
  IApplicationVideoQuestions,
  IBaseApplication,
  AskForCvRequest,
} from '../model/application.interface';
import { ApplicationJob } from '../model/job.interface';
import { ApplicationEmailResponse } from './email-response.class';
import { Email, ResponseEmails } from './email.class';
import { Company } from './organization.class';
import { IEmail } from '../model/email.interface';
import { EmailNotification } from './notification.class';
import { User } from '../model/user.interface';
import { QUIZ_MODULES } from '../resources/quiz-modules';
import { IComment } from '../model/comment.interface';
import { IHiringStatus } from '../model/hiring-status.interface';
import { ISms } from '../model/sms.interface';
import { changeVideoExtension } from '../resources/video-url-transformation-functions';
import { Comment } from './comments.class';
import { SmsMessage } from './sms-messages.class';
import { IFile } from '../model/upload-files.model';
import { convertStringDateToJSDate } from '../resources/shared-functions';
import { Tag } from '../model/tag.interface';
import { IAgreement } from '../model/agreement.model';

export class BaseApplication {

  businessQuestionsScore: number;
  businessQuestionsStatusIconColor: string;
  candidate: Candidate;
  candidateName: string;
  communicated: boolean;
  createdAt: Date;
  emails: Email[];
  id: number;
  inhouseQuestionAnsweredCorrectly: boolean;
  job?: ApplicationJob;
  knockoutQuestionsScore: number;
  orderOfQuestionsModules: QUIZ_MODULES[];
  responseEmails: ResponseEmails;
  videoQuestionsStatusIconColor: string;
  askForCvRequest?: AskForCvRequest;
  profileImage: string;

  constructor(app: IBaseApplication, company: Company, currentUser: User) {
    this.askForCvRequest = app.askForCvRequest;
    this.businessQuestionsScore = app.businessQuestionsScore;
    this.businessQuestionsStatusIconColor = app.businessQuestionsStatusIconColor;
    this.candidate = app.candidate;
    this.candidateName = app.candidateAppliedWithName;
    this.communicated = app.communicated;
    this.setEmails(app, company, currentUser);
    this.id = app.id;
    this.inhouseQuestionAnsweredCorrectly = app.inhouseQuestionAnsweredCorrectly;
    this.job = app.job || app.universalJob;
    this.knockoutQuestionsScore = app.knockoutQuestionsScore;
    this.orderOfQuestionsModules = app.orderOfQuestionsModules;
    this.videoQuestionsStatusIconColor = app.videoQuestionsStatusIconColor;
    this.profileImage = 'https://docs.highercasting.com/customer/887cfe34dee546df9062c993294a62de/image2.jpg';
  }

  public static convertEmails(emails: IEmail[], company: Company, currentUser: User): { emails: Email[], responseEmails: ResponseEmails } {
    const responseEmails = new ResponseEmails();

    const newEmails = emails
      .map((iEmail: IEmail) => {
        const email = new Email(iEmail, company, currentUser);

        email.applicationEmailResponses
          .forEach(({unreadResponse}: ApplicationEmailResponse) => {
            if (unreadResponse) {
              responseEmails.hasUnreadResponses = true;

              email.senderEmail === currentUser.email
                ? responseEmails.numberOfOwnUnreadResponses++
                : responseEmails.numberOfUnreadResponsesFromOthers++;
            }
          });

        return email;
      });

    const emailWithResponse = newEmails.find((email: Email) => email.applicationEmailResponses.length > 0);
    responseEmails.hasResponses = !!emailWithResponse;

    return { emails: newEmails, responseEmails };
  }

  private setEmails(app: IBaseApplication, company: Company, currentUser: User): void {

    const { emails, responseEmails } = BaseApplication.convertEmails(app.applicationEmails, company, currentUser);

    this.emails = emails;
    this.responseEmails = responseEmails;
  }
}

export class Application extends BaseApplication {

  candidatePersonalDataRemoved: boolean;
  page: number;
  pinned: boolean;
  position: number;

  constructor(app: IApplication, company: Company, currentUser: User, pinned: boolean = false) {
    super(app, company, currentUser);

    // on apps per hiring status we dont get proper date object
    this.createdAt = convertStringDateToJSDate(app.createdAt as string);
    this.candidatePersonalDataRemoved = app.candidatePersonalDataRemoved;
    this.pinned = pinned;

    if (pinned) {
      this.position = app.pinnedApplications
        .find((pinnedApp: IPinnedApplication) => {
          const pinnedByUser = pinnedApp.pinnedByCustomer || pinnedApp.pinnedByEnterpriseManager;
          return pinnedByUser.email === currentUser.email;
        })?.position;
    }
  }

  public setResponseEmailsFromNotifications(receivedEmails: EmailNotification[], user: User): void {
    this.responseEmails.hasUnreadResponses = false;
    this.responseEmails.numberOfOwnUnreadResponses = 0;
    this.responseEmails.numberOfUnreadResponsesFromOthers =  0;

    const receivedEmailsForCurrentApp = receivedEmails
      .filter(({applicationId}: EmailNotification) => applicationId === this.id);

    if (!receivedEmailsForCurrentApp.length) {
      return;
    }

    this.responseEmails.hasResponses = true;
    const unreadResponsesForCurrentApp = receivedEmailsForCurrentApp
      .filter(({readBy}: EmailNotification) => !readBy.includes(user.email));

    if (!unreadResponsesForCurrentApp.length) {
      return;
    }

    this.responseEmails.hasUnreadResponses = true;
    const unreadResponsesFromCurrentUser = unreadResponsesForCurrentApp
      .filter(({sender}: EmailNotification) => sender.email === user.email);
    this.responseEmails.numberOfOwnUnreadResponses = unreadResponsesFromCurrentUser.length;
    this.responseEmails.numberOfUnreadResponsesFromOthers =  unreadResponsesForCurrentApp.length - unreadResponsesFromCurrentUser.length;
  }
}

export class CandidateApplication extends BaseApplication {

  applicationComplete: boolean;
  applicationUrl: string;
  businessQuestions: TestQuestion;
  businessQuestionsMetaData: TestQuestion;
  comments: Comment[];
  documents: IFile[];
  guid: string;
  hiringStatus: IHiringStatus;
  inhouseQuestion: TestQuestion;
  knockoutQuestions: TestQuestion;
  phoneNumber: string;
  smsMessages: SmsMessage[];
  tags: Tag[];
  videoQuestions: ApplicationVideoQuestion[] = [];
  agreements: IAgreement[];
  uploadedVideosByCustomer: string[];

  constructor(app: ICandidateApplication, currentUser: User, company: Company) {
    super(app, company, currentUser);

    this.applicationComplete = app.applicationComplete;
    this.applicationUrl = app.applicationUrl;
    this.businessQuestions = app.businessQuestions;
    this.businessQuestionsMetaData = app.businessQuestionsMetaData;
    this.comments = app.comments.map((comment: IComment) => new Comment(comment, currentUser));
    this.createdAt = app.createdAt as Date;
    this.documents = app.documents;
    this.mergeAllEmails(currentUser, company);
    this.emails = this.sortEmails(this.emails);
    this.guid = app.guid;
    this.hiringStatus = app.hiringStatus;
    this.inhouseQuestion = app.inhouseQuestion;
    this.knockoutQuestions = app.knockoutQuestions;
    this.phoneNumber = app.phoneNumber;
    this.smsMessages = app.smsMessages.map((message: ISms) => new SmsMessage(message, company));
    this.tags = app.tags;
    this.setVideoQuestions(app.videoQuestions);
    this.agreements = app.applicationAgreements;
    this.uploadedVideosByCustomer = app.uploadedVideosByCustomer;
  }

  private mergeAllEmails(currentUser: User, company: Company): void {
    let askForCvEmails = [];

    if (this.askForCvRequest) {
      const { applicationAskForCvRequestEmails } = this.askForCvRequest;
      askForCvEmails = this.setOtherTypesOfEmails(company, currentUser, applicationAskForCvRequestEmails);
    }

    this.emails = [...this.emails, ...askForCvEmails];
  }

  private setOtherTypesOfEmails(company: Company, currentUser: User, emails: IEmail[]): Email[] {
    if (!emails) {
      return [];
    }

    return emails.map((iEmail: IEmail) => new Email(iEmail, company, currentUser));
  }

  private setVideoQuestions(videoQuestions: IApplicationVideoQuestions): void {
    if (!videoQuestions) {
      return;
    }

    this.videoQuestions.push(new ApplicationVideoQuestion(videoQuestions.q1, videoQuestions.q1answer));
    this.videoQuestions.push(new ApplicationVideoQuestion(videoQuestions.q2, videoQuestions.q2answer));
    this.videoQuestions.push(new ApplicationVideoQuestion(videoQuestions.q3, videoQuestions.q3answer));
  }

  private sortEmails(emails: Email[]): Email[] {
    emails.forEach((sentEmail: Email) => {
      let emailDate = sentEmail.createdAt;

      if (sentEmail.applicationEmailResponses) {
        sentEmail.applicationEmailResponses
          .forEach(({createdAt}: ApplicationEmailResponse) => {
            if (createdAt.localeCompare(emailDate) === 1) {
              emailDate = createdAt;
            }
          });
      }

      sentEmail.sortDate = emailDate;
    });

    emails = emails
      .sort((a: Email, b: Email) => {
        return new Date(b.sortDate).valueOf() - new Date(a.sortDate).valueOf();
      });

    return emails;
  }
}

export class ApplicationVideoQuestion {

  question: string;
  answer: string;
  poster: string;
  externalVideo: boolean;

  constructor(question: string, answer: string) {
    this.question = question;
    this.answer = answer;
    this.externalVideo = true;

    if (answer) {
      this.poster = changeVideoExtension(answer, 'jpg');
      if (answer.includes('cast')) {
        this.externalVideo = false;
      }
    }
  }
}
