import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  ICandidateApplication,
  UploadDocument,
  PreviousApplication,
  RecruitmentApplicationFilters,
  OpenCandidateCard
} from '../model/application.interface';
import { tap, map } from 'rxjs/operators';
import { CommentPayload, IComment } from '../model/comment.interface';
import { Pagination } from '../model/pagination.interface';
import { UniversalOption } from '../model/universal-option.interface';
import { Comment } from '../classes/comments.class';
import { UserService } from './user.service';
import { CandidateApplication } from '../classes/application.class';
import { SetupService } from './setup.service';
import { JobIds } from '../model/job.interface';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentService {

  private _changedStatuses$: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);
  private _refreshedApplication: Subject<CandidateApplication> = new Subject<CandidateApplication>();
  private _reorderApplication: Subject<any> = new Subject<any>();
  private _openNewCandidateCard$: Subject<OpenCandidateCard> = new Subject<OpenCandidateCard>();
  private _getTags$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private _filters: any;
  private _searchBy: any;
  private _orderBy: string;
  private _changedStatusesSubject$: Subject<number[]> = new Subject<number[]>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private setupService: SetupService
  ) { }

  get refreshedApplication$(): Observable<CandidateApplication> {
    return this._refreshedApplication.asObservable();
  }

  get changedStatusesSubject$(): Observable<number[]> {
    return this._changedStatusesSubject$.asObservable();
  }

  setRefreshedApplication(application: CandidateApplication): void {
    this._refreshedApplication.next(application);
  }

  get changedStatuses$(): Observable<number[]> {
    return this._changedStatuses$.asObservable();
  }

  setChangedStatuses(status: number[]): void {
    this._changedStatuses$.next(status);
  }

  get reorderedApplications$(): Observable<any> {
    return this._reorderApplication.asObservable();
  }

  reorderApplications(newIndex: number, hiringStatusId: number, previousIndex: number, applicationId?: number): void {
    this._reorderApplication.next({previousIndex, newIndex, hiringStatusId, applicationId});
  }

  get openNewCandidateCard$(): Observable<OpenCandidateCard> {
    return this._openNewCandidateCard$.asObservable();
  }

  get getTags$(): Observable<void> {
    return this._getTags$.asObservable();
  }

  setRecruitmentAppFilters({filters, searchBy, orderBy}: RecruitmentApplicationFilters): void {
    this._orderBy = orderBy;
    this._filters = filters;
    this._searchBy = searchBy;
  }

  setApplication(application: ICandidateApplication): CandidateApplication {
    return new CandidateApplication(application, this.userService.user, this.setupService.currentCompany);
  }

  getApplication(applicationId: number): Observable<CandidateApplication> {
    return this.http.get<Pagination<ICandidateApplication>>(`${environment.applications}/${applicationId}`)
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
      );
  }

  createComment(commentPayload: CommentPayload): Observable<CandidateApplication> {
    return this.http.post(`${environment.comments}`, commentPayload)
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
        tap((application: CandidateApplication) => {
          this.setRefreshedApplication(application);
        })
      );
  }

  deleteComment(commentid: number): Observable<Comment[]> {
    return this.http.delete(`${environment.comments}/${commentid}`)
      .pipe(
        map((comments: IComment[]) => {
          return comments.map((comment: IComment) => {
            return new Comment(comment, this.userService.user);
          });
        }
      ));
  }

  markSeenComments(commentId: number): Observable<CandidateApplication> {
    const payload = { applicationComments: [commentId] };

    return this.http
      .post(`${environment.comments}/add_customers_seen_tag`, payload)
      .pipe(
        map((application: ICandidateApplication) => this.setApplication(application)),
        tap((application: CandidateApplication) => {
          this.setRefreshedApplication(application);
        })
      );
  }

  openCandidateModal(applicationId: number, hiringStatusId: number): void {
    this._openNewCandidateCard$.next({applicationId, hiringStatusId});
  }

  createTag(tag: UniversalOption, id: number): Observable<CandidateApplication> {
    return this.http.post(`${environment.applications}/${id}/application_tags`, tag)
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
        tap((application: CandidateApplication) => {
          this._getTags$.next();
          this.setRefreshedApplication(application);
        })
      );
  }

  deleteTag(tag: UniversalOption, id: number): Observable<CandidateApplication> {
    return this.http.delete(`${environment.applications}/${id}/application_tags/${tag.id}`)
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
        tap((application: CandidateApplication) => {
          this._getTags$.next();
          this.setRefreshedApplication(application);
        })
      );
  }

  getTags(jobs?: JobIds, locationTag?: boolean): Observable<UniversalOption[]> {
    const companyId = this.setupService.currentCompany.id;
    const jobIds = jobs?.jobIds;
    const universalJobId = jobs?.universalJobId;
    let params = new HttpParams();


    if (jobIds && jobIds.length) {
      params = params.append('jobIds', jobIds.toString());
    }

    if (universalJobId) {
      params = params.append('universalJob', universalJobId.toString());
    }

    if (!locationTag) {
      params = params.append('locationTag', locationTag.toString());
    }

    params = params.append('company', companyId.toString());
    params = params.append('page', '1');
    params = params.append('limit', '10000');

    return this.http.get<Pagination<UniversalOption>>(`${environment.applicationTags}`, {params})
      .pipe(
        map(({data}: Pagination<UniversalOption>) => data)
      );
  }

  updateApplicationStatus(
    applicationId: number,
    newHiringStatus: number,
    previousStatusId: number,
    newIndex?: number,
    pinned?: boolean
  ): Observable<CandidateApplication> {
    const payload = { hiringStatus: newHiringStatus };

    return this.http
      .post<Pagination<ICandidateApplication>>(
        `${environment.applications}/${applicationId}/application_hiring_status`,
        payload
      )
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
        tap((application: CandidateApplication) => {
          if (pinned) {
            this.reorderApplications(newIndex, newHiringStatus, null, applicationId);
            this._changedStatuses$.next([previousStatusId]);
          } else {
            this._changedStatuses$.next([newHiringStatus, previousStatusId]);
            this._changedStatusesSubject$.next([newHiringStatus, previousStatusId]);
          }

          this.setRefreshedApplication(application);
        })
      );
  }

  setNewOrder(applications: number[]): Observable<string> {
    return this.http.put<string>(`${environment.pinnedApplications}/change_positions`, {applications});
  }

  uploadDocument(payload: UploadDocument): Observable<CandidateApplication> {
    return this.http.post(`${environment.api}/application_documents`, payload)
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
        tap((application: CandidateApplication) => this.setRefreshedApplication(application))
      );
  }

  deleteDocument(documentId: number): Observable<CandidateApplication> {
    return this.http.delete(`${environment.api}/application_documents/${documentId}`)
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => this.setApplication(data[0])),
        tap((application: CandidateApplication) => this.setRefreshedApplication(application))
      );
  }

  getPreviousApplications(candidateId: number, page: number, perPage: number): Observable<Pagination<PreviousApplication>> {
    let params = new HttpParams();

    params = params.append('page', `${page}`);
    params = params.append('limit', `${perPage}`);

    return this.http.get<Pagination<PreviousApplication>>(`${environment.candidates}/${candidateId}/applications`, { params });
  }

  getApplicationsByHiringStatus(
    statusId: number,
    page: number,
    jobs: string[],
    universalJobId?: string,
    pinned?: boolean,
  ): Observable<Pagination<ICandidateApplication>> {
    let params = this.setHttpParams(jobs, universalJobId, page);

    if (pinned) {
      params = params.append('pinned', '1');
      params = params.append('limit', '1000');
    } else {
      params = params.append('orderByCandidatePersonalDataRemoved', 1);
      params = params.append('limit', '20');
    }

    return this.http.get<Pagination<ICandidateApplication>>(
      `${environment.applicationsByHiringStatus}/${statusId}`,
      { params }
    );
  }

  getApplicationsForAnInteview(
    statusId: number,
    page: number,
    jobs: string[],
    universalJobId?: string
  ): Observable<Pagination<ICandidateApplication>> {
    let params = this.setHttpParams(jobs, universalJobId, page);

    params = params.append('limit', '10000');
    params = params.append('candidatePersonalDataRemoved', 0);

    return this.http.get<Pagination<ICandidateApplication>>(
      `${environment.applicationsByHiringStatus}/${statusId}`,
      { params }
    );
  }

  getAllStatusApplicationIds(
    statusId: number,
    jobs: string[],
    universalJobId?: string
  ): Observable<Partial<ICandidateApplication[]>> {
    let params = this.setHttpParams(jobs, universalJobId);

    params = params.append('candidatePersonalDataRemoved', '0');
    params = params.append('limit', '1000000');
    params = params.append('onlyId', 'true');

    return this.http
      .get(
        `${environment.applicationsByHiringStatus}/${statusId}`,
        { params }
      )
      .pipe(
        map(({data}: Pagination<ICandidateApplication>) => data),
      );
  }

  pinApplicationByHiringStatus(applicationId: number, position: number): Observable<ICandidateApplication> {
    return this.http.post<ICandidateApplication>(`${environment.applications}/${applicationId}/pin`, { position });
  }

  unpinApplicationByHiringStatus(applicationId: number): Observable<ICandidateApplication> {
    return this.http.delete<ICandidateApplication>(`${environment.applications}/${applicationId}/unpin`);
  }

  setHttpParams(jobs: string[], universalJobId?: string, page: number = 1): HttpParams {
    let params: HttpParams = new HttpParams();

    params = params.append('page', page.toString());

    if (jobs) {
      params = params.append('jobIds', jobs.toString());
    }

    if (universalJobId) {
      params = params.append('universalJob', universalJobId.toString());
    }

    if (this._filters) {
      if (this._filters['unreadApplicationEmailResponses']) {
        this._filters['unreadApplicationEmailResponsesFromOthers'] = true;
      }

      Object
        .keys(this._filters)
        .forEach((filterKey: string) => {
          if (this._filters[filterKey] === null) {
            return;
          }

          if (filterKey === 'hasSkillsTest') {
            return;
          }

          if (filterKey.includes('skillsTo') || filterKey.includes('skillsFrom')) {
            if (this._filters.hasSkillsTest) {
              params = params.append(filterKey, this._filters[filterKey]);
              if ( !params.has('candidatePersonalDataRemoved') ) {
                params = params.append('candidatePersonalDataRemoved', 0);
              }
            }
            return;
          }

          if (filterKey === 'complete' && this._filters.complete === false) {
            params = params.append('onlyIncompleteApplications', 1);
            if ( !params.has('candidatePersonalDataRemoved') ) {
              params = params.append('candidatePersonalDataRemoved', 0);
            }
            return;
          }

          if (filterKey === 'video' && this._filters.video === false) {
            params = params.append('onlyIncompleteVideoQuestions', 1);
            if ( !params.has('candidatePersonalDataRemoved') ) {
              params = params.append('candidatePersonalDataRemoved', 0);
            }
            return;
          }

          params = params.append(filterKey, this._filters[filterKey] ? '1' : '0');
          if ( !params.has('candidatePersonalDataRemoved') ) {
            params = params.append('candidatePersonalDataRemoved', 0);
          }
        });
    }

    if (this._searchBy) {
      if (typeof this._searchBy === 'string' || this._searchBy instanceof String) {
        params = params.append('searchBy', this._searchBy.toString());
      } else {
        for (const property in this._searchBy) {
          let value = this._searchBy[property];
          if (value && value.length) {
            if (Array.isArray(value)) {
              value = value.map(item => item.id);
            }
            params = params.append(property, value);
          }
        }
      }
    }

    if (this._orderBy) {
      params = params.append('orderBy', this._orderBy);
    }

    return params;
  }

  updatePhone(applicationGuid: string, phoneNumber: string): Observable<string> {
    return this.http.put<string>(`${environment.applications}/${applicationGuid}/candidate_data_by_employee`, { phoneNumber });
  }

  attachVideoToApplication(applicationGuid: string, video: string): Observable<string> {
    return this.http.post<string>(`${environment.applications}/${applicationGuid}/upload_video_by_customer`, { video });
  }

  deleteVideoAttachmentFromApplication(applicationGuid: string, video: string): Observable<string> {
    return this.http.post<string>(`${environment.applications}/${applicationGuid}/remove_video_by_customer`, { video });
  }
}

